<template>
    <div class="completeInformation">
        <div class="cominfor centerWidth marginAuto">
            <div class="cominforTitle flex spacebetween alignCenter">
                <div class="cominforTitleText c33 fontW ">{{comInforType == 1 ? '完善信息' : '完善信息-医院信息'}}</div>
                <div class="cominforTitleSkip flex spacearound alignCenter" @click="goother({path:'/'})">
                    跳过
                </div>
            </div>
            <div v-if="comInforType == 1" class="cominforPersonal flex spacearound alignCenter">
                <cominfor-personal :cominfor="comInfor" :personal="userInfo" ref="personal"></cominfor-personal>
            </div>
            <div v-if="comInforType == 2" class="cominfororg flex spacearound alignCenter flexColumn" style="padding-bottom:50px;">
                <cominfor-org :edit="true" :cominfor="comInfor" :org="userInfo" ref="org"></cominfor-org>
            </div>
        </div>
        <div v-if="comInforType == 2" class="orgcominfor centerWidth marginAuto">
            <div class="cominforTitle flex spacebetween alignCenter">
                <div class="cominforTitleText c33 fontW ">完善信息-联系人信息</div>
            </div>
            <div class="flex spacearound alignCenter">
                <cominfor-org-person ref="orgPerson" :edit='true' :cominfor="comInfor" :org="userInfo"></cominfor-org-person>
            </div>
        </div>
        <div class="MyLike centerWidth marginAuto">
            <my-like ref="myLike"></my-like>
        </div>
        <button class="cominforSubmit cursorP" @click="cominforSubmit">提交</button>
    </div>
</template>
<script>
import { edit_user } from '@/utils/Api/userList'
import { org_edit_user } from '@/utils/Api/orgList'
import cominforPersonal from '@/components/cominforPersonal'
import cominforOrg from '@/components/cominforOrg'
import cominforOrgPerson from '@/components/cominforOrgPerson'
import myLike from '@/components/myLike'
export default {
    name:'completeInformation',
    components:{cominforPersonal, cominforOrg, cominforOrgPerson, myLike},
    data(){
        return{
            comInforType: 1,                // 完善信息类型  1 个人 2 机构
        }
    },
    created(){
        this.comInforType = this.$route.query.comInforType == 1 ? 1 : 2
    },
    methods:{
        /* 提交按钮 */
        async cominforSubmit(){
            let commonData, jumpTurn
            /* 我的关注 */
            const mylike = this.$refs.myLike.MyLike
            if(this.comInforType == 1){
                commonData = this.$refs.personal.personal
                commonData.portrait = '/upload' + commonData.portrait.split('upload')[1] 
                jumpTurn = (await edit_user({...commonData, type_ids: mylike.join(',')})) ? true : false
            }
            if(this.comInforType == 2){
				// console.log(this.$refs.org.org)
				/* , ...this.$refs.org.org.hospital_business, ...this.$refs.org.org.hospital_info */
                commonData = {...this.$refs.org.org, ...this.$refs.orgPerson.org, ...this.$refs.org.org.hospital}
				commonData.hos_address = commonData.address,
				commonData.address = '',
                commonData.portrait = '/upload' + commonData.portrait.split('upload')[1] 
                jumpTurn = (await org_edit_user({...commonData, type_ids: mylike.join(',')})) ? true : false
            }
            if(jumpTurn){
                this.$message.success('信息完善成功')
                setTimeout(() => {
                    this.goother({path:'/'})
                }, 1500)
                return
            }
            this.$message.error('信息完善失败')
        },
        /* 数据初始化 */
        async initial(){

        },
        
    },
    computed:{
        userInfo(){
            let user = this.$store.getters.personal
            user.hospital = {}
            user.president = {}
			if(user.id && !this.$store.getters.personal.province_id){
				if(this.$refs.personal){
					this.$refs.personal.assignaddressbinds([user.province_id, user.city_id, user.zone_id])	
				}
			}
            // user.hospital = {}
            // console.log(user)
            return user
        },
        comInfor(){
            return this.$store.getters.cominfor
        }
    },
}
</script>

<style lang="scss" scoped>
.checkListSChecked{
    color: #fff!important;
    background: #47D7E3;
}
.orgcominfor{
    height: 420px;
    margin-top: 40px;
    background: #fff;
}
.cominforSubmit{
    width: 176px;
    height: 53px;
    font-size: 16px;
    color: #fff;
    background: #47D7E3;
    margin-top: 38px;
    margin-bottom: 38px;
}
.MyLike{
    min-height: 265px;
    background: #fff;
    margin-top: 20px;
    padding-bottom: 36px;
    .cominforTitle{
        border:none
    }
    .checkList{
        width: 1020px;
        float: right;
        margin-right: 32px;
        min-height: 82px;
        .checkListS{
            width: 131px;
            height: 53px;
            border: 1px solid #c6c6c6;
            border-radius: 2px;
            color: #C6C6C6;
            margin-top: 29px;
        }
    }
}
    .cominforPersonal{
        padding-bottom: 50px;
    }
    table{
        margin-top: 30px;
        tr{
            height: 66px;
            td{
                font-size: 15px;
                color: #393939;
                &:nth-child(3){
                    display: inline-block;
                    margin-left: 67px;
                    line-height: 66px;
                }
            }
        }
    }
    .completeInformation{
        width: 100%;
        min-height: 977px;
        background: #fafafa;
        overflow: hidden;
        .cominfor{
            margin-top: 28px;
            min-height: 500px;
            background: #fff;
        }
    }
    .cominforTitle{
        width: 100%;
        height: 88px;
        border-bottom: 1px solid #dedede;
        .cominforTitleText{
            font-size: 20px;
            margin-left: 39px;
        }
        .cominforTitleSkip{
            width: 76px;
            height: 32px;
            opacity: 1;
            background: #47d7e3;
            font-size: 16px;
            color: #fff;
            margin-right: 64px;
            cursor: pointer;
        }
    }


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>